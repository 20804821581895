import { createContext, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { forEach, includes, isNumber, isString, kebabCase } from 'lodash'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import i18nRessources from 'src/layout/i18n.json'
import { ConfigProvider, theme, message } from 'antd'
import dayjs from 'dayjs';
import localeFr from 'antd/locale/fr_FR';
import localeEn from 'antd/locale/en_US';
import { Settings } from 'luxon'

import 'dayjs/locale/fr';
import 'dayjs/locale/en';

const { defaultAlgorithm, darkAlgorithm, useToken } = theme

const UIContext = createContext(null)

let browserLanguage = navigator.language.slice(0, 2);
if (![ 'fr', 'en' ].includes(browserLanguage)) {
  browserLanguage = 'en'
}

export const useUI = () => {
  return useContext(UIContext)
}

const locales = {
  'fr': localeFr,
  'en': localeEn
}

export const UIProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage()
  const persistedTheme = localStorage.getItem('theme')
  const persistedLang = localStorage.getItem('lang')
  const [theme, setTheme] = useState(persistedTheme || 'system')
  const [disableAccept, setDisableAccept] = useState(false)
  const [logoPreview, setLogoPreview] = useState(false)
  const [lang, setLang] = useState(persistedLang || browserLanguage)
  const [backUrl, setBackUrl] = useState(false)
  const [isPageLoading, setPageLoading] = useState(false)
  const [promptInstall, setPromptInstall] = useState(null);

  const darkMode = useMemo(() => {
    localStorage.setItem('theme', theme)

    
    const value = !theme || theme === 'system' ? window.matchMedia('(prefers-color-scheme: dark)').matches : theme === 'dark'
    
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', value ? '#000' : '#fff');
    }

    return value
  }, [theme])  
  

  useEffect(() => {
    const handler = e => {
      e.preventDefault();
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  useEffect(() => {
    localStorage.setItem('lang', lang)
    i18n.changeLanguage(lang)
    dayjs.locale(lang)
  }, [lang])

  return (
    <UIContext.Provider
      value={{
        promptInstall,
        lang,
        setLang,
        setTheme,
        setBackUrl,
        backUrl,
        darkMode,
        contextHolder,
        messageApi,
        setPageLoading,
        isPageLoading,
        setLogoPreview,
        logoPreview,
        disableAccept,
        setDisableAccept
      }}
    >
      <ConfigProvider
        locale={locales[lang]}
        theme={{
          cssVar: true,
          algorithm: darkMode ? darkAlgorithm : defaultAlgorithm,
          token: {
            fontFamily: 'Roboto',
            colorBgLayout: darkMode ? '#0f0f0f' : '#f5f5f5',
            colorBgBase: darkMode ? '#000000' : '#ffffff',
            fontWeightStrong: '500',
            colorBorderLayout: darkMode ? '#0f0f0f' : '#e2e2e2',
            marginLG: 16,
          },
          components: {
            Button: {
              defaultGhostColor: 'red',
            },
            Divider: {
              verticalMarginInline: 4,
            },
            Segmented: {
              itemActiveBg: darkMode ? '#0f0f0f' : '#e2e2e2',
            },
            Tabs: {
              verticalItemMargin: 0,
              horizontalMargin: 0,
              verticalItemPadding: '8px 16px',
              horizontalItemGutter: 6,
            },
            Popover: {
              innerPadding: 0,
            },
            Typography: {
              fontWeightStrong: '500',
            },
            Drawer: {
              colorBgMask: 'transparent',
            },
            Form: {
              itemMarginBottom: 0,
            },
            Avatar: {
              groupBorderColor: 'none',
            },
          },
        }}
      >
        <CssTokenBridge />

        {children}
      </ConfigProvider>
    </UIContext.Provider>
  )
}

// @TODO: Get browser language
i18n.use(initReactI18next).init({
  resources: i18nRessources,
  lng: localStorage.getItem('lang') || browserLanguage,
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false,
  },
})

i18n.on('languageChanged', (lng) => {
  Settings.defaultLocale = lng
})

Settings.defaultLocale = localStorage.getItem('lang') || browserLanguage

function saveToken(value, tokenName) {
  const isPrivateField = tokenName.startsWith('_')
  if (isPrivateField) return
  const variableName = `--ant-${kebabCase(tokenName)}`
  if (isString(value)) document.documentElement.style.setProperty(variableName, value)
  if (isNumber(value)) {
    const propertyValue = isPureNumberProperty(tokenName) ? value : `${value}px`
    document.documentElement.style.setProperty(variableName, propertyValue)
  }
}

const isPureNumberProperty = (tokenName) =>
  includes(tokenName, 'zIndex') ||
  includes(tokenName, 'Weight') ||
  includes(tokenName, 'motion') ||
  includes(tokenName, 'opacity') ||
  includes(tokenName, 'lineHeight')

const CssTokenBridge = () => {
  const { token } = useToken()
  useLayoutEffect(() => {
    forEach(token, saveToken)
  }, [token])

  return null
}
